:root{
  --circle-score-size: 10px;
  --circle-score-color: rgb(175,175,175);
  --circle-score-border-color: rgb(77,77,77);
  --actions-bar-circle-info: 28px;
}

.case-actions-bar {
  height: 48px;
  width: 100%;
  display: flex;
  /* padding: 2px; */
  border: 1px solid rgb(176,176,176);
  border-bottom: 1px solid rgb(176,176,176);
}
.case-actions-bar-left{
  display: flex;
  align-items: center;
  /* width: 60%; */
  /* justify-content: space-around; */
  justify-content: center;
  color: rgb(0, 63, 106);
  font-weight: bold; 
  font-size: 14px;
  border-right: 1px solid rgb(184,184,184);
  padding: 0px 5px;
}
.case-actions-bar-middle{
  display: flex;
  border-right: 1px solid rgb(184,184,184);
  margin-left: 5px;
  justify-content: space-around;
  align-items: center;
}
.case-actions-bar-right{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.case-actions-bar-middle-container{
  margin-right: 5px;
}

.action-share-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.circle-info {
  height: var(--actions-bar-circle-info);
  width: var(--actions-bar-circle-info);
  border-radius: 50%;
  background-color: red;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  /* align-content: center; */
  vertical-align: center;
}

.case-view-container {
  width: 100%;
  height: auto;
}

.case-image {
  width: 100%;
  height: auto;
}

.case-info-block-title {
  margin-bottom: 10px;
  font-size: 12px;
  color: #4d4d4d;
}

.case-info-block-value {
  margin-bottom: 10px;
  font-size: 13px;
  font-family: ProximaNovaRegular;
  color: #4d4d4d;
}

.case-view-container-separator
{
  display:none;
}


@media (min-width: 769px) {
  .caseDetailsView
  {
    position: absolute;
    left: 0%;
    top: 100%;
    height: auto;
    width: 100%;
    background-color: white;
    z-index: 100;
    border: 1px solid rgb(176,176,176);
    border-top: none;
    padding-left: 20px;
  }

  .scrollBarContainer{

    margin-top: 5px;
    margin-left: 40px;
  }
}



@media (max-width: 768px) {

  .case-info-block-title {
    font-size: 12px;
  }
  
  .case-info-block-value {
    font-size: 13px;
  }

  .case-view-container-separator
  {
    display: block;
    height: 8px;
    background-color: rgb(176,176,176);
  }
  
  .case-actions-bar-left{
    font-size: 15px;
  }

  .case-view-container {
    width: 100%;
  }

  .case-actions-bar {
    height: 48px;
    width: 100%;
    display: flex;
    /* padding: 2px; */
    border: none;
    border-bottom: 1px solid rgb(176,176,176);
  }
}

@media (max-width: 568px) {
  .case-actions-bar-left{
    font-size: 15px;
  }
}