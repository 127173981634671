.details-container {
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

.case-info-block {
  line-height: 15px;
  margin-bottom: 20px;
  word-wrap: break-word;
  white-space: normal;
  color: #4d4d4d;
  font-size: 13px;
}

.case-title {
  font-size: 12px;
  font-weight: bold;
  /* margin-bottom: 25px; */
}

.case-subtitle {
  font-size: 13px;
  &-highlighted {
    background: gold;
  }
}

.categories-container {
  display: flex;
  margin-bottom: 12px;
}

.category-container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  color: #4d4d4d;
}

.category-container p {
  margin-bottom: 5px;
  font-size: 12px;
}

.circle-score {
  height: var(--circle-score-size);
  width: var(--circle-score-size);
  border-radius: 50%;
  margin-right: 18px;
  display: block;
  background-color: var(--circle-score-color);
  border: 2.8px solid var(--circle-score-border-color);
}

.circle-score-empty {
  background-color: transparent;
  border: 2.5px solid rgb(174,174,174);
}


@media (max-width: 568px) {

  .category-container {
    width: 50vw;
  }
}
@media (max-width: 768px) {
  .category-container p {
    font-size: 13px;
  }

  .circle-score {
    margin-right: 10px;
  }

  .details-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
