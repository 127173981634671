.customTitleContainer {
  padding-top: 10px;
}
.customTitleText {
  letter-spacing: 9.62px;
  font-size: 33px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .customTitleContainer {
    padding-top: 12px;
  }
  .customTitleText {
    font-size: 17px;
    letter-spacing: 6.5px;
    font-weight: bold;
  }
}