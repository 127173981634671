:root{
    --btn-width: 220px;
    --btn-width-mobile: 85px;

    --btn-text-color: #006f7f;
    --btn-background-color: white;

    --font-size: 16px;
    --font-size-mobile: 16px;

    --text-height: 30px;
    --text-height-mobile: 30px;
}
.customBtn {
  display: flex;
  justify-content: center;
  border: 2px solid var(--btn-text-color);
  height: var(--text-height);
  width: auto;
  padding: 2px 5px;
  background-color: var(--btn-background-color);
}
.customBtnText {
  font-size: var(--font-size);
  color: var(--btn-text-color);
  font-family: ProximaNovaRegular;
}
@media (max-width: 768px) {
  .customBtn {
    display: flex;
    justify-content: center;
    /* border: none; */
    height: var(--text-height-mobile);
    width: auto;
    background-color: var(--btn-background-color);
  }
  .customBtnText {
    font-size: var(--font-size-mobile);
    color: var(--btn-text-color);
  }
}
