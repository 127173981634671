:root{
    --title-font-size: 30px;
    --description-font-size: 20px;
}

.terms-container{
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 20px;
}

.terms-title{
    font-size: var(--title-font-size);
}

.terms-description{
    font-size: var(--description-font-size);
}