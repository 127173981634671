:root {
  --cases-card-size: 450px;
  --cases-card-size-mobile: 100%;
}

.cases-grid {
  width: 100%;
  display: grid;
  grid-gap: 40px;
  margin-top: 20px;
  /* justify-content: center; */
  /* grid-template-columns: repeat(auto-fill, var(--cases-card-size)); */
  grid-template-columns: 33% 33% 33%;
}

@media (max-width: 1300px) {
  .cases-grid {
    width: 100%;
    display: grid;
    grid-gap: 30px;
    margin-top: 0px;
    grid-template-columns: 50% 50%;
  }
}
@media (max-width: 768px) {
  .cases-grid {
    width: 100%;
    display: grid;
    grid-gap: 0px;
    margin-top: 0px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, var(--cases-card-size-mobile));
  }
}
