@import "./fonts.scss";

* {
  font-family: ProximaNovaLight, sans-serif;
}

.icucBody {
  width: 100%;
  /* margin-top: 88px; */
  position: absolute;
}

.icucBody.active {
  display: none;
  visibility: hidden;
}
