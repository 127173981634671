.card-container {
  position: relative;
  margin: 0 auto;
  width: 200px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  /* -webkit-transition: all 0.2s; 
      transition: all 0.2s; */
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.card-container:hover {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.65);
}

.card-picture {
  width: 100%;
  height: 180px;
  background-size: cover;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-color: rgba(256, 256, 256, 0.5);
}

.card-container:hover .card-picture {
  opacity: 1;
}

.card-title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto;
  text-align: center;
}