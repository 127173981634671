.input2-container {
  width: 407px;
  height: 63px;
  background-color: white;
  margin-top: 16px;
}
.input2-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.input2-style {
  border: none;
  width: 300px;
  height: 55px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .input2-container {
    width: 290px;
    height: 51px;
    background-color: white;
    margin-top: 16px;
  }
  .input2-inner-container {
    width: 80%;
    height: 100%;
    margin-left: 15px;
  }
  .input2-style {
    border: none;
    width: 300px;
    height: 35px;
    font-size: 14px;
  }
}
