



.input-search-container {
  width: 507px;
  height: 63px;
  background-color: white;
}
.input-search-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgb(160, 160, 160);
}
.input-search-style {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-left: 10px;
  background-color: rgb(227, 227, 227);
  border-radius: 0px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input-search-inner-container input:focus {
  outline: none;
}

@media (max-width: 768px) {


  .input-search-container {
    width: 70vw;
    height: 48px;
    /* margin-top: 10px; */
    background-color: white;
  }
  .input-search-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .input-search-style {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
}

