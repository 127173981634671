:root {
  --hamburger-menu-color: rgb(0, 111, 127);
  --hamburger-width: 26px;
  --hamburger-height: 16px;
}

.topNavBar {
  display: block;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  position: fixed;
  background: white;
  z-index: 100;
  height: auto;
  /* box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2); */
}
.topNavBarContainer {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 100;
  line-height: 1.5 !important;
  border-bottom: 1px solid rgb(0,111,127);
  padding-top: 15px;
  padding-bottom: 25px;
  /* box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2); */
}

.breadcrumbsDiv {
  display: "fixed";
  margin-top: 1px;
  background-color: white;
  /* margin-bottom: 2px; */
  border-bottom: 1px solid rgb(174,174,174);
  color: rgb(77,77,77);
  font-size: 17px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 50px;
}

.menu-option-container {
  position: fixed;
  background-image: url("../../../assets/backgroundHome.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 0px center;
}

.menuOptionContainer {
  position: fixed;
  /* background-image: url("../../../assets/backgroundHome.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.menu-option-sub-container {
  /* padding-top: 99px; */
  height: auto;
  min-height: initial;
  width: 100%;
  background-color: white;
  position: absolute;
  padding-bottom: 20px;
}

.searchInputDesktopContainer
{
  position: relative;
  visibility:hidden;
  width: 0px;
  transition: visibility 0.3s ease-out, width 0.3s ease-out;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.searchInputDesktopContainer.show
{
  
  visibility:visible;
  width:507px;
}
.cancelSearchInput
{
  position: absolute;
  right: 15px;
  /* visibility:hidden; */
  opacity: 0;
  /* transition: all 0.3s ease-out 0.2s; */
}

.cancelSearchInput.show
{
  visibility:visible;
  opacity:1;
  /* transition: all 0.5s linear 1s !important; */
}

.menuOption {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
}

.menuOption p {
  margin-bottom: 0 !important;
}

.menuOptionText {
  font-size: 16px;
  color: rgb(0,63,106);
}

.menuOptionOtherText {
  font-size: 16px;
  color: rgb(0,63,106);
}

.menuOptionOtherTextDisable {
  font-size: 16px;
  color: grey;
}

.logo-with-info {
  display: flex;
  cursor: pointer;
}

.icuc-logo {
  margin-left: 8px;
  margin-top: 10px;
  font-size: 30px;
  font-family: LatoThin;
  font-weight: 200;
  letter-spacing: 2.55px;
}

.logoNavBar {
  height: 60px;
  cursor: pointer;
}

.navbar-action-bar {
  display: flex;
}

.navbar-search-bar {
  
  margin-right: 15px;
}
.navbar-action-bar .selected {
  margin-right: 0px;
}

.hamburger {
  margin-right: 15px;
  width: var(--hamburger-width);
  height: var(--hamburger-height);
  position: relative;
  cursor: pointer;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hamburger .line {
  width: var(--hamburger-width);
  margin: 0 auto;
  height: 1.5px;
  position: absolute;
  display: block;
  background: var(--hamburger-menu-color);
  right: 0;
  transition: all 650ms;
  border-radius: 60px;
}

.hamburger .line:nth-child(1) { 
  /* margin-bottom: 0px; */
  top: 0%;
  
}
.hamburger .line:nth-child(2) {
  /* margin-top: 9px;
  margin-bottom: 9px; */
  top: 50%;
  margin-top: -1px;
}
.hamburger .line:nth-child(3) {
  /* margin-top: 0px; */
  top: 100%;
  margin-top: -2px;
}

.hamburger.active .line:nth-child(1) {
  transform: translateY(calc(var(--hamburger-height)/2)) rotate(45deg);
  width: var(--hamburger-width);
}
.hamburger.active .line:nth-child(2) {
  opacity: 0;
  margin-top: 0px;
}
.hamburger.active .line:nth-child(3) {
  transform: translateY(calc((var(--hamburger-height)/2) * -1) ) rotate(-45deg);
  width: var(--hamburger-width);
  margin-top: 0px;
}

.collapseMenuHalfDesktop 
{
  width: 50%;
  display: flex;
  justify-content: space-between;
}

/* .collapseMenuHalfDesktop 
{
  width: 50%;
  display: flex;
  justify-content: space-between;
} */

.collapseMenuHalfDesktopLeft 
{
  padding-right: 20px;
}

.collapseMenuHalfDesktopRight 
{
  padding-left: 20px;
}

.menuActiveIndicatorLine
{
  height: 3px;
  width: 100%;
  background-color: rgb(0,63,106);
  position: absolute; 
}

@media (min-width: 769px) {

  .breadcrumbsDiv {  
    height: 48px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 768px) {

  .navbar-search-bar {
  
    margin-right: 12px;
  }

  .menuOption {
    padding-left: 25px;
  }
  .menuOptionText {
    font-size: 15px;
  }

  .menuOptionOtherText {
    font-size: 14px;
  }

  .menuOptionOtherTextDisable {
    font-size: 14px;
  }

  .menu-option-sub-container {
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
    padding-bottom: 0px;
    margin-top: 12px;
  }

/*     
  .topNavBar {

  } */
  .topNavBarContainer {
    border-bottom: 1px solid #003f6a;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .breadcrumbsDiv {
    border-bottom: 1px solid #003f6a;
    font-size: 14px;
    color: #003f6a;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    font-weight: bold;
  }
}
