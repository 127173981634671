:root {
  --div-size: 70px;
  --card-size: 70px;
  --padding-size: 3px;
}
.region-card {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  // &.selected{
  //   background-color: white;
  // }
}

.region-card-container {
  width: var(--div-size);
  height: var(--div-size);
  // border-radius: calc(var(--div-size) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4d4d4d;
  
  &.selected {
    border: 2px solid rgb(0, 63, 106);
  }

}
.region-card-container.selected {
  border: 4px solid rgb(0, 63, 106);
}

.region-card-image {
  width: calc(var(--card-size) - var(--padding-size));
  height: calc(var(--card-size) - var(--padding-size));
  background-size: cover;
  opacity: 0.8;
  background-repeat: no-repeat;

  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.region-card-title-container {
  width: 100%;
  height: auto;
  // text-align: center;
  padding-left: 2px;
  margin-top: 8px;
  overflow-wrap: break-word !important;
  white-space: normal;
  line-break: 0px;
}

.region-card-title {
  width: 100%;
  font-size: 13px;
  line-height: 1.3;
  color: #4d4d4d;

}
