.customDatePicker {
  width: 100%;
  height: 100%;
  font-size: 16px;
  border: none;
  background-color: rgb(227, 227, 227);
  padding-left: 20px;
}

.react-datepicker-wrapper 
{
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container 
{
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .customDatePicker {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
}
