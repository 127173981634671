.customInputContainer {
  width: 487px;
  height: 63px;
  background-color: white;
  margin-top: 16px;
}
.customInputInnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 2px solid rgb(160, 160, 160);
  position: relative;
}
.customInputStyle {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-left: 20px;
  background-color: rgb(227, 227, 227);
}

.customInputInnerContainer input[type="date"]:before {
  content: attr(placeholder) !important;
  /* margin-right: 0.5em; */
}

.customInputInnerContainer input[type="date"]:focus:before,
.customInputInnerContainer input[type="date"]:valid:before {
  content: "";
}

.customSelectStyle{
  border: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-left: 20px;
  background-color: rgb(227, 227, 227);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

}

.arrowDisplaySelectInput 
{
  position: absolute;
  right: 15px;
  pointer-events: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

@media (max-width: 768px) {
  .customInputContainer {
    width: 329px;
    height: 51px;
    background-color: white;
    margin-top: 16px;
  }
  .customInputInnerContainer {
    width: 100%;
    height: 100%;
    
  }
  .customInputStyle {
    /* border: none; */
    width: 100%;
    height: 100%;
    padding-left: 15px;
    font-size: 16px;
  }

  .customSelectStyle{
    width: 100%;
    height: 100%;
    padding-left: 15px;
    font-size: 16px;
    border: none;

  }

}