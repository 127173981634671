@font-face {
    font-family: ProximaNovaRegular;
    src: url('../fonts/ProximaNova-Regular.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: ProximaNovaLight;
    src: url('../fonts/ProximaNova-Light.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: ProximaNovaSemiBold;
    src: url('../fonts/ProximaNova-Semibold.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Light.ttf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: LatoThin;
    src: url('../fonts/Lato-Thin.ttf') format("opentype");
    font-weight: 400;
    font-style: normal;
}