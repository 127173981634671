.centerScrollBar {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.buttonScroll {
  display:none;
  border: none;
  outline: none;
}

.buttonContainer {
  display: flex;
}

.buttonScrollRight 
{
  margin-right: 17px;
}

@media screen and (max-width: 768px) {
  .buttonScroll {
    display: none !important;
  }
  .centerScrollBar {
    overflow-x: scroll;
  }
}
