

@media (min-width: 769px) {

    .findUsOnContainer
    {
        display: flex;
        width: 50%;
        justify-content: start; 
        align-items: center;
    }
    
    .findUsOnContainer .list-inline
    {
        width: 100%;
        padding-left: 30px !important;
    }
    
    .findUsOnText
    {
        width: 300px;
    }

    .termsPrivacySeparator
    {
        display: none;
    }

    .termsPrivacyContainer
    {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-right: 30px;
        font-size: 18px;
    }
    
    .footerDataContainer
    {
        padding-top: 50px;
        padding-bottom: 40px;
        display: flex;
        width: 100%;
    }

    .termsPrivacyText{
        padding-right: 50px;
    }
}

@media (max-width: 768px) {
    .findUsOnContainer
    {
        display: block;   
    }

    .findUsOnText
    {
        margin-bottom: 30px;
        width: 100%;
        font-size: 22px;
    }

    .termsPrivacyText
    {
        text-decoration: underline;
    }

    .termsPrivacyContainer
    {
        margin-top: 30px;  
        padding-bottom: 25px;  
        display: flex;
        font-size: 16px;
    }

    .footerDataContainer
    {
        padding-top: 50px;
        padding-bottom: 20px;
    }

}