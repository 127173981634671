.title-auth {

  &-title {
    font-size: 45px;
    color: rgb(16, 75, 115);
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &-line {
    display: inline-block;
    visibility: visible;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 2px;
    width: 170px;
    background: #006f7f;
    @media (max-width: 768px) {
      display: none;
      visibility: hidden;
    }
  }
}
